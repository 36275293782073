<template>
    <div>
        <div
            style="
                font-size: 34px;
                font-weight: bold;
                margin-bottom: 22px;
                color: red;
            "
        >
            <p><span style="font-size: 14px">Sử dụng class: </span>*</p>
        </div>

        <p class="text-h1 text-bold">Desktop</p>
        <div style="padding-left: 32px">
            <div class="section-type">
                <div class="grid-section">
                    <p class="title-1">Title 1</p>
                </div>
                <div class="grid-section">
                    <p class="title-2">Title 2</p>
                </div>
                <div class="grid-section">
                    <p class="title-3">Title 3</p>
                </div>

                <div class="grid-section">
                    <p class="body-large-bold">body-large-bold</p>
                </div>
                <div class="grid-section">
                    <p class="body-large-semi-bold">body-large-semi-bold</p>
                </div>
                <div class="grid-section">
                    <p class="body-large-medium">body-large-medium</p>
                </div>
                <div class="grid-section">
                    <p class="body-medium-bold">body-medium-bold</p>
                </div>
                <div class="grid-section">
                    <p class="body-medium-semi-bold">body-medium-semi-bold</p>
                </div>
                <div class="grid-section">
                    <p class="body-medium-medium">body-medium-medium</p>
                </div>
                <div class="grid-section">
                    <p class="body-medium-regular">body-medium-regular</p>
                </div>
                <div class="grid-section">
                    <p class="body-small-semi-bold">body-small-semi-bold</p>
                </div>
                <div class="grid-section">
                    <p class="body-small-medium">body-small-medium</p>
                </div>
                <div class="grid-section">
                    <p class="body-small-regular">body-small-regular</p>
                </div>
                <div class="grid-section">
                    <p class="body-extra-small-semi-bold">
                        body-extra-small-semi-bold
                    </p>
                </div>
                <div class="grid-section">
                    <p class="body-extra-small-regular">
                        body-extra-small-regular
                    </p>
                </div>
                <div class="grid-section">
                    <p class="body-extra-small-small-cap">
                        body-extra-small-small-cap
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Typography",
    data() {
        return {
            ctas: [
                {
                    Name: "",
                    Label: "Primary Info",
                    type: "primary",
                    icon: "",
                },
                {
                    Name: "",
                    Label: "Primary Info",
                    type: "primary",
                    icon: "",
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.is-title {
    font-weight: 600;
    font-size: 25px;
    color: #000000;
}
.section-type {
    padding-top: 24px;
    gap: 40px;
    width: 100%;
    .grid-section {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 3fr;
        gap: 40px;
        padding-bottom: 16px;
    }
    .block-info {
        padding-top: 10px;
    }
}
</style>
